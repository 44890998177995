import React, { useEffect } from 'react'
import type { SelectProps } from 'antd'
import { Select, Spin, Typography } from 'antd'
import useFetch from 'hooks/use-fetch'
import { USER_LIST } from 'api/user'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'

interface Props {
   onChange: any
   value: string
}

const UserFilter = (props: Props) => {
   const { t } = useTranslation()

   const [rows, setRows] = React.useState<any[]>([])

   const [search, setSearch] = React.useState<string>('')

   const [searchDebounced] = useDebounce(search, 600)

   const [fetchUserQuery, { data: users, loading, error }] = useFetch<any>()

   const handleChange = (value: string[]) => {
      props.onChange(value)
   }

   const onSearch = (value: string) => {
      //@ts-ignore
      fetchUserQuery(USER_LIST, {
         endPoint: `users-search?search=${value}`
      })
   }

   useEffect(() => {
      if (searchDebounced) {
         onSearch(searchDebounced)
      }
   }, [searchDebounced])

   let notFoundContent: any = t('common.searchByUser')
   if (loading || search !== searchDebounced) {
      notFoundContent = <Spin size="small" style={{ margin: 'auto', width: '100%' }} />
   } else if (searchDebounced) {
      notFoundContent = <Typography.Text>{t('common.noData')}</Typography.Text>
   }

   let options = users?.result?.map((user: any) => {
      return {
         label: user.name,
         value: user.id
      }
   })

   const handleBlur = (event: any) => {
      setSearch('')
   }

   return (
      <>
         <div className="flex flex-col">
            <span className="font-bold">{t('common.searchByUser')}</span>
            <Select
               filterOption={false}
               onSearch={setSearch}
               loading={loading}
               notFoundContent={notFoundContent}
               options={options}
               onBlur={handleBlur}
               onChange={handleChange}
               showSearch
               labelInValue
               allowClear
               autoClearSearchValue
            />
         </div>
      </>
   )
}

export default UserFilter
