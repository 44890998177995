import React, { useEffect } from 'react';

const TradingViewWidget = ({ isDarkMode }:any) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
//     script.innerHTML = `
//       {
//         "symbols": [
//           {
//             "proName": "FX_IDC:EURUSD",
//             "title": "EUR/USD"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:AAPL"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:TSLA"
//           },
//           {
//             "description": "",
//             "proName": "FX:GBPUSD"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:AMZN"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:FB"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:MSFT"
//           },
//           {
//             "description": "",
//             "proName": "NASDAQ:NFLX"
//           }
//         ],
//         "showSymbolLogo": true,
//         "colorTheme": "${isDarkMode ? 'dark' : 'light'}",
//         "isTransparent": true,
//         "locale": "en"
//       }
//     `;
    
//     const container = document.createElement('div');
//     container.className = 'tradingview-widget-container';
//     container.style.width = '100%';
//     container.style.height = '100px'; // Adjust the height as needed
//     container.style.background = isDarkMode ? '#1e1e1e' : '#ffffff'; // Set background color based on mode

//     const widgetContainer = document.createElement('div');
//     widgetContainer.className = 'tradingview-widget-container__widget';

//     container.appendChild(widgetContainer);
//     document.body.appendChild(container);
//     container.appendChild(script);

//     return () => {
//       document.body.removeChild(container);
//     };
//   }, [isDarkMode]);

  return (
    <div className='container border border-gray-100 mx-auto my-10'>
        <iframe className='w-full h-[50px]' src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AAAPL%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ATSLA%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX%3AGBPUSD%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AAMZN%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AFB%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AMSFT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ANFLX%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Atrue%2C%22width%22%3A%22100%25%22%2C%22height%22%3A44%2C%22utm_source%22%3A%22www.myfctrade.qabak.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.myfctrade.qabak.com%2Fen%2Findex-user%3Fdo%3Ddashboard%26token%3D1641b392ac9f777add92dcbca9c1639f%22%7D"></iframe>
    </div>
  );
};

export default TradingViewWidget;