import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import { Form, Switch, message } from 'antd'
import { APP_LIST, APP_LIST_URL, APP_UPDATE, APP_UPDATE_URL } from 'api/app'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useTransition } from 'react'
import { Input } from 'rizzui'
import UploadComponent from './Upload'
import { apiUrl } from 'api'
import { useTranslation } from 'react-i18next'

export default function EditAppSettings() {
   const { t } = useTranslation()
   const [appSettings, { loading, error, data }] = useFetch<any>()
   const [updateAppMutation, { loading: updateAppLoading, error: updateAppError, data: updateAppData }] =
      useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      appSettings(APP_LIST, {
         endPoint: APP_LIST_URL
      })
   }, [])

   const [form] = Form.useForm()

   const onFinish = (values: any) => {
      console.log(values)
      //@ts-ignore
      updateAppMutation(APP_UPDATE, {
         endPoint: APP_UPDATE_URL,
         body: values
      })
   }

   useEffect(() => {
      if (data) {
         const app = data?.data

         form.setFieldsValue({
            app_name: app.app_name,
            logo: app.logo,
            mail: app.mail,
            live_reload_stock: app.live_reload_stock,
            live_reload_currency: app.live_reload_currency
         })
      }
   }, [data])

   useEffect(() => {
      if (updateAppData) {
         message.success(t('messages.update_success'))
      }
   }, [updateAppData])

   return (
      <div>
         <Card radius="sm">
            <CardHeader>
               <h4>{t('aside.app_settings')}</h4>
            </CardHeader>
            <CardBody>
               <Form layout="vertical" form={form} onFinish={onFinish}>
                  <Form.Item
                     rules={[
                        {
                           required: true,
                           message: t('validation.thisFieldIsRequired')
                        }
                     ]}
                     name="app_name"
                     label={t('app_settings.app_name')}
                  >
                     <Input className="ant-input" />
                  </Form.Item>
                  <Form.Item name="logo" label="Logo">
                     <UploadComponent
                        form={form}
                        file={{
                           name: 'logo',
                           url: apiUrl + '/' + data?.data?.logo
                        }}
                     />
                     <input className="ant-input" type="hidden" />
                  </Form.Item>
                  <Form.Item name="mail" label="Mail">
                     <Input className="ant-input" />
                  </Form.Item>
                  <Form.Item name="live_reload_stock" label="Live Reload Stock" valuePropName="checked">
                     <Switch />
                  </Form.Item>
                  <Form.Item name="live_reload_currency" label="Live Reload Currency" valuePropName="checked">
                     <Switch />
                  </Form.Item>
                  <Button type="submit" color="primary" isLoading={updateAppLoading}>
                     Save
                  </Button>
               </Form>
            </CardBody>
         </Card>
      </div>
   )
}
