import { Button, Chip, User, Card, Input, CardHeader, CardBody } from '@nextui-org/react'
import { Form, Modal, Popconfirm, Table, TableColumnProps, message } from 'antd'
import {
   REFERRALS_DELETE,
   REFERRALS_LIST,
   REFERRALS_LIST_URL,
   REFERRALS_STORE,
   REFERRALS_STORE_URL,
   REFERRALS_UPDATE,
   REFERRALS_UPDATE_URL
} from 'api/referrals'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReferralsForm from './Form'
import TableComponents from 'components/Table'
import ActionButton from './ActionButton'
import SearchInput from 'components/Input'
import UserFilter from 'components/FilterContainer/User'

export default function Referrals() {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.referral'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.referrals.list,
            name: t('aside.referral')
         }
      ]
   }

   const [page, setPage] = React.useState(1)
   const [row, setRow] = React.useState<any>({}) // row selection
   const [addModal, setAddModal] = React.useState(false)
   const [editModal, setEditModal] = React.useState(false)
   const [search, setSearch] = React.useState('') // search keyword
   const [selectedUser, setSelectedUser] = React.useState<any>(null)

   const addOnOpenModal = () => setAddModal(true)
   const addOnCloseModal = () => setAddModal(false)
   const addOpenModal = addModal
   const editOnOpenModal = () => setEditModal(true)
   const editOnCloseModal = () => setEditModal(false)
   const editOpenModal = editModal

   const [referralsQuery, { data, loading }] = useFetch<any>()

   const [addReferralsMutation, { data: addReferralsData, loading: addReferralsLoading }] = useFetch<any>()

   const [editReferralsMutation, { data: editReferralsData, loading: editReferralsLoading }] = useFetch<any>()

   const [deleteReferralsMutation, { data: deleteReferralsData, loading: deleteReferralsLoading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(REFERRALS_LIST, {
         endPoint: REFERRALS_LIST_URL.replace(':page', page.toString() + `&search=${search}`)
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.user'),
         dataIndex: 'user',
         key: 'user',
         render: (user: any) => {
            return (
               <div className="flex items-center gap-3">
                  {user.name} {user.last_name}
               </div>
            )
         }
      },
      {
         title: t('common.transactionType'),
         dataIndex: 'type',
         key: 'type',
         className: 'capitalize',
         render: (type: any) => {
            return <div className="flex items-center gap-3">{type}</div>
         }
      },
      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat(amount)}</div>
         }
      },
      {
         title: t('common.createdAt'),
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (createdAt: any) => {
            return <div className="flex items-center gap-3">{moment(createdAt).format('DD.MM.YYYY')}</div>
         }
      },
      {
         title: t('common.description'),
         dataIndex: 'description',
         key: 'description'
      },
      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* <Button
              onClick={() => {
                setRow(record);
                editOnOpenModal();
              }}
              color="primary"
              variant="faded"
            >
              {t("buttons.edit")}
            </Button>
            <Popconfirm
              title={t("messages.delete_confirm")}
              onConfirm={() => {
                //@ts-ignore
                deleteReferralsMutation(REFERRALS_DELETE, {
                  endPoint: REFERRALS_UPDATE_URL + "/" + id,
                });
              }}
              okButtonProps={{
                loading: deleteReferralsLoading,
                className: "bg-red-500",
                style: { background: "red" },
              }}
              okText={t("buttons.delete")}
              cancelText={t("buttons.cancel")}
            >
              <Button color="danger" variant="faded">
                {t("buttons.delete")}
              </Button>
            </Popconfirm> */}
                  <ActionButton
                     editButtonClick={() => {
                        setRow(record)
                        editOnOpenModal()
                     }}
                     deleteButtonClick={() => {
                        //@ts-ignore
                        Modal.confirm({
                           title: t('messages.delete_confirm'),
                           okText: t('buttons.delete'),
                           cancelText: t('buttons.cancel'),
                           onOk: () => {
                              //@ts-ignore
                              deleteReferralsMutation(REFERRALS_DELETE, {
                                 endPoint: REFERRALS_UPDATE_URL + '/' + id
                              })
                           }
                        })
                     }}
                  />
               </div>
            )
         }
      }
   ]

   const [addForm] = Form.useForm()

   const [editForm] = Form.useForm()

   const onFilter = () => {
      let object: any = {}

      if (selectedUser) {
         object.user_id = selectedUser
      }

      if (search) {
         object.search = search
      }

      let query = new URLSearchParams(object).toString()

      //@ts-ignore

      referralsQuery(REFERRALS_LIST, {
         endPoint: REFERRALS_LIST_URL.replace(':page', (1).toString() + `&${query}`),
         body: object
      })
   }

   const onAddFinish = (values: any) => {
      //@ts-ignore
      addReferralsMutation(REFERRALS_STORE, {
         endPoint: REFERRALS_STORE_URL,
         body: values
      })
   }

   const onUpdateFinish = (values: any) => {
      //@ts-ignore
      editReferralsMutation(REFERRALS_UPDATE, {
         endPoint: REFERRALS_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   const onDeleteFinish = (values: any) => {
      //@ts-ignore
      deleteReferralsMutation(REFERRALS_DELETE, {
         endPoint: REFERRALS_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   useEffect(() => {
      if (addReferralsData) {
         message.success(t('messages.add_success'))
         addOnCloseModal()
         //@ts-ignore
         referralsQuery(REFERRALS_LIST, {
            endPoint: REFERRALS_LIST_URL.replace(':page', page.toString())
         })
         addForm.resetFields()
      }
   }, [addReferralsData])

   useEffect(() => {
      if (editReferralsData) {
         message.success(t('messages.update_success'))
         editOnCloseModal()
         //@ts-ignore
         referralsQuery(REFERRALS_LIST, {
            endPoint: REFERRALS_LIST_URL.replace(':page', page.toString())
         })
         editForm.resetFields()
      }
   }, [editReferralsData])

   useEffect(() => {
      if (deleteReferralsData) {
         message.success(t('messages.delete_success'))
         //@ts-ignore
         referralsQuery(REFERRALS_LIST, {
            endPoint: REFERRALS_LIST_URL.replace(':page', page.toString())
         })
      }
   }, [deleteReferralsData])

   return (
      <div>
         <Modal title={t('buttons.add')} open={addOpenModal} onCancel={addOnCloseModal} footer={null}>
            <Form form={addForm} onFinish={onAddFinish} layout="vertical">
               <ReferralsForm form={addForm} isLoading={addReferralsLoading} onFinish={onAddFinish} />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={addOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={addReferralsLoading}>
                     {t('buttons.add')}
                  </Button>
               </div>
            </Form>
         </Modal>
         <Modal title={t('referrals.edit.title')} open={editOpenModal} onCancel={editOnCloseModal} footer={null}>
            <Form form={editForm} onFinish={onUpdateFinish} layout="vertical">
               <ReferralsForm
                  form={editForm}
                  isLoading={editReferralsLoading}
                  onFinish={onUpdateFinish}
                  initialValues={row}
               />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={editOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={editReferralsLoading}>
                     {t('buttons.save')}
                  </Button>
               </div>
            </Form>
         </Modal>

         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <Button color="primary" onClick={addOnOpenModal}>
               {t('buttons.add')}
            </Button>
         </PageHeader>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-5 gap-2 items-center">
                  <UserFilter onChange={(e: any) => setSelectedUser(e.value)} value={selectedUser} />
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />
                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={t('aside.referral')}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}
