import React from 'react'

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, cn } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react'
import { Popconfirm } from 'antd'

interface ActionButtonProps {
   deleteButtonClick: any
   editButtonClick: any
   otherButtonClick: any
}

function ActionButton(props: ActionButtonProps) {
   const iconClasses = 'text-xl text-default-500 pointer-events-none flex-shrink-0'

   const { t } = useTranslation()

   return (
      <div>
         <Dropdown>
            <DropdownTrigger>
               <Button variant="faded">
                  {t('common.actions')} <i className="ml-2 icon-arrow-down" />
               </Button>
            </DropdownTrigger>
            <DropdownMenu variant="faded" aria-label="Dropdown menu with icons">
               <DropdownItem
                  onClick={props.editButtonClick}
                  key="edit"
                  startContent={<IconEdit className={iconClasses} />}
               >
                  {t('buttons.edit')}
               </DropdownItem>
               <DropdownItem
                  onClick={props.deleteButtonClick}
                  key="delete"
                  className="text-danger"
                  color="danger"
                  startContent={<IconTrash className={cn(iconClasses, 'text-danger')} />}
               >
                  {t('buttons.delete')}
               </DropdownItem>
               <DropdownItem
                  onClick={props.otherButtonClick}
                  key="show"
                  startContent={<IconEye className={cn(iconClasses, 'text-danger')} />}
               >
                  {t('common.showUserProfile')}
               </DropdownItem>
            </DropdownMenu>
         </Dropdown>
      </div>
   )
}

export default ActionButton
