import {
   Button,
   CircularProgress,
   Input,
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   useDisclosure
} from '@nextui-org/react'
import { IconSearch, IconChevronDown } from '@tabler/icons-react'
import { Card, Form, Select, message } from 'antd'
import { STOCK_LIST, STOCK_LIST_URL, STOCK_STORE, STOCK_STORE_URL } from 'api/stock'
import { USER_STOCK_STORE, USER_STOCK_STORE_URL } from 'api/userStock'
import axios from 'axios'
import ReactSelect from 'components/Select'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDebounce } from 'hooks/use-debounce'
import { Combobox, Transition } from '@headlessui/react'

function BuyStock() {
   const { t } = useTranslation()
   const [form] = Form.useForm()
   const [messageApi, contextHolder] = message.useMessage()
   const [isAdmin, setIsAdmin] = useState(false)
   const key = 'updatable'

   const [getStockQuery, { data: stockData, loading, error }] = useFetch<any>()
   const [getStockListQuery, { data: stockListData, loading: searchLoading }] = useFetch<any>()

   const [addNewStockMutation, { data: addNewStockData, loading: addNewStockLoading }] = useFetch<any>()

   const [addUserStockMutation, { data: addUserStockData, loading: addUserStockLoading }] = useFetch<any>()

   const [data, setData] = useState<any>([])

   const [selected, setSelected] = useState<any>(null)

   const [modal, setModal] = useState(false)

   const [users, setUsers] = useState<any>([])

   const [search, setSearch] = useState('')
   const debouncedSearch = useDebounce(search, 1000)

   const [query, setQuery] = useState('')

   const filteredStocks =
      query === ''
         ? stockData?.data
         : stockData?.data?.filter((stock: any) =>
            (stock.short_name + stock.symbol)
               .toLowerCase()
               .includes(query.toLowerCase())
         )

   const handleAddNewStock = (row: any) => {
      console.log(row)
      messageApi.open({
         key,
         content: t('messages.addingNewStock'),
         duration: 0
      })
      //@ts-ignore
      addNewStockMutation(STOCK_STORE, {
         endPoint: STOCK_STORE_URL,
         method: 'POST',
         body: row
      })
   }

   const handleFinish = (values: any) => {
      console.log(values)
      let object: any = {}

      if (isAdmin) {
         object.historical_price = values.historical_price
         object.user_id = values.user_id
      } else {
         object.historical_price = selected?.regular_market_price
      }

      //@ts-ignore
      addUserStockMutation(USER_STOCK_STORE, {
         endPoint: USER_STOCK_STORE_URL,
         method: 'POST',
         body: {
            stockId: values.stock,
            quantity: values.quantity,
            ...object
         }
      })
   }

   const getUserInfo = async () => {
      const userInfo = JSON.parse(localStorage.getItem('user') || '{}')

      if (userInfo.user_type == 'admin') {
         const { data } = await axios.get('/users-all')
         console.log(data)
         setUsers(data.data)
         setIsAdmin(true)
      }
   }

   useEffect(() => {
      getUserInfo()
      //@ts-ignore
      getStockQuery(STOCK_LIST, {
         endPoint: 'stocks' + '/all-stock'
      })
   }, [])

   useEffect(() => {
      if (debouncedSearch !== '') {
         //@ts-ignore
         getStockListQuery(STOCK_LIST, {
            endPoint: 'stocks' + '/search-stock?query=' + debouncedSearch
         })
      }
   }, [debouncedSearch])

   useEffect(() => {
      if (stockData) {
         const newData = stockData?.data?.map((stock: any, index: any) => ({
            value: stock.id,
            label:
               stock.short_name +
               ' (' +
               stock.symbol +
               ')' +
               ' | ' +
               stock.regular_market_price +
               ' | ' +
               stock.regular_market_change +
               ' | ' +
               stock.regular_market_change_percent
         }))

         setData(newData)
      }
   }, [stockData])

   useEffect(() => {
      if (addNewStockData) {
         if (addNewStockData.status == true) {
            messageApi.open({
               key,
               type: 'success',
               content: t('messages.addingNewStockSuccess'),
               duration: 2
            })
         } else {
            messageApi.open({
               key,
               type: 'error',
               content: t('messages.' + addNewStockData.message),
               duration: 2
            })
         }

         //@ts-ignore
         getStockQuery(STOCK_LIST, {
            endPoint: 'stocks' + '/all-stock'
         })
      }
   }, [addNewStockData])

   useEffect(() => {
      if (addNewStockLoading) {
         message.loading(t('messages.addingNewStockSuccess'))
      } else {
         message.destroy()
      }
   }, [])

   useEffect(() => {
      if (addUserStockData) {
         if (addUserStockData.status == true) {
            messageApi.open({
               type: 'success',
               content: t('messages.buyingStockSuccess'),
               duration: 2
            })
            setTimeout(() => {
               window.location.reload()
            }, 2000)
         } else {
            messageApi.open({
               type: 'error',
               content: t('messages.' + addUserStockData.message),
               duration: 2
            })
         }
      }
   }, [addUserStockData])

   const { isOpen, onOpen, onOpenChange } = useDisclosure()

   return (
      <div>
         {contextHolder}
         <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={true} size="3xl">
            <ModalContent>
               {onClose => (
                  <>
                     <ModalHeader className="flex flex-col gap-1">{t('common.addModalTitle')}</ModalHeader>
                     <ModalBody>
                        <Input
                           onChange={e => setSearch(e.target.value)}
                           size="sm"
                           autoFocus
                           startContent={
                              <IconSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                           }
                           endContent={searchLoading && <CircularProgress />}
                           placeholder={t('search')}
                           variant="faded"
                           classNames={{
                              input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                           }}
                        />
                        <div style={{ maxHeight: 500, overflow: 'scroll' }} className="p-0">
                           {stockListData?.data?.map((stock: any, index: any) => (
                              <div
                                 className="flex flex-col gap-2 p-3 border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors"
                                 onClick={() => {
                                    handleAddNewStock(stock)
                                    onClose()
                                 }}
                                 key={index}
                              >
                                 <div className="flex justify-between items-start">
                                    <div className="flex flex-col">
                                       <span className="text-lg font-bold">
                                          {stock.short_name} ({stock.symbol})
                                       </span>
                                       <span className="text-sm text-gray-600">
                                          {stock.exchange_name} • {stock.exchange_country} • {stock.industry_name}
                                       </span>
                                    </div>
                                    <div className="flex flex-col items-end">
                                       <span className="text-lg font-semibold">
                                          {stock.currency} {stock.regular_market_price.toLocaleString(undefined, {
                                             minimumFractionDigits: 2,
                                             maximumFractionDigits: 2
                                          })}
                                       </span>
                                       <div className="flex items-center gap-2">
                                          <span
                                             className={`font-medium ${stock.regular_market_change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                          >
                                             {stock.regular_market_change > 0 ? '↑' : '↓'}{' '}
                                             {Math.abs(stock.regular_market_change).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                             })}
                                          </span>
                                          <span
                                             className={`text-sm ${stock.regular_market_percent_change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                          >
                                             ({stock.regular_market_percent_change.toFixed(2)}%)
                                          </span>
                                       </div>
                                    </div>
                                 </div>
                                 {stock.trade_type && (
                                    <div className="text-xs px-2 py-1 bg-gray-100 rounded-full w-fit">
                                       {stock.trade_type}
                                    </div>
                                 )}
                              </div>
                           ))}
                        </div>
                     </ModalBody>
                     <ModalFooter></ModalFooter>
                  </>
               )}
            </ModalContent>
         </Modal>
         <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
               label={t('common.selectStock')}
               name="stock"
               rules={[
                  {
                     required: true,
                     message: t('validation.stockId')
                  }
               ]}
            >
               <Combobox
                  as="div"
                  value={selected}
                  onChange={(stock: any) => {
                     setSelected(stock)
                     form.setFieldsValue({
                        stock: stock.id,
                        regular_market_price: stock.regular_market_price
                     })
                  }}
               >
                  <div className="relative">
                     <div className="relative w-full">
                        <Combobox.Input
                           className="w-full rounded-lg border border-gray-200 bg-white py-2 pl-3 pr-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                           displayValue={(stock: any) =>
                              stock ? `${stock.short_name} (${stock.symbol})` : ''
                           }
                           onFocus={() => {
                              setQuery('')
                           }}
                           onChange={(event) => setQuery(event.target.value)}
                           placeholder={t('common.selectStock')}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                           <IconChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>
                     </div>
                     <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                     >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                           {filteredStocks?.length === 0 && query !== '' ? (
                              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                 {t('common.nothingFound')}
                              </div>
                           ) : (
                              filteredStocks?.map((stock: any) => (
                                 <Combobox.Option
                                    key={stock.id}
                                    className={({ active }) =>
                                       `relative cursor-pointer select-none py-2 px-4 ${active ? 'bg-primary-50 text-primary-900' : 'text-gray-900'
                                       }`
                                    }
                                    value={stock}
                                 >
                                    {({ selected, active }) => (
                                       <div className="flex flex-col gap-1">
                                          <div className="flex justify-between">
                                             <div className="flex flex-col">
                                                <span className={`font-medium ${selected ? 'font-semibold' : ''}`}>
                                                   {stock.short_name}
                                                </span>
                                                <span className="text-sm text-gray-500">
                                                   {stock.symbol} • {stock.exchange || stock.exchange_name}
                                                </span>
                                             </div>
                                             <div className="flex flex-col items-end">
                                                <span className="font-medium">
                                                   {stock.currency} {parseFloat(stock.regular_market_price).toLocaleString()}
                                                </span>
                                                <span
                                                   className={`text-sm ${parseFloat(stock.regular_market_change) > 0
                                                      ? 'text-green-500'
                                                      : parseFloat(stock.regular_market_change) < 0
                                                         ? 'text-red-500'
                                                         : 'text-gray-500'
                                                      }`}
                                                >
                                                   {parseFloat(stock.regular_market_change) > 0 ? '↑' : '↓'}{' '}
                                                   {Math.abs(parseFloat(stock.regular_market_change)).toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2
                                                   })}{' '}
                                                   ({parseFloat(stock.regular_market_change_percent).toFixed(2)}%)
                                                </span>
                                             </div>
                                          </div>
                                          {stock.industry_name && (
                                             <span className="text-xs px-2 py-1 bg-gray-100 rounded-full w-fit">
                                                {stock.industry_name}
                                             </span>
                                          )}
                                       </div>
                                    )}
                                 </Combobox.Option>
                              ))
                           )}
                        </Combobox.Options>
                     </Transition>
                  </div>
               </Combobox>
               <div className="mt-1 gap-2 flex items-center">
                  <span>{t('common.couldntFind')}</span>
                  <Button variant="ghost" size="sm" onPress={onOpen}>
                     {t('common.clickHere')}
                  </Button>
               </div>
               {selected && (
                  <div className="border border-gray-200 rounded-lg mt-3 p-4 bg-gray-50">
                     <div className="flex justify-between items-start">
                        <div className="flex flex-col gap-1">
                           <div className="flex items-center gap-2">
                              <span className="text-lg font-bold">
                                 {selected.short_name}
                              </span>
                              <span className="text-sm text-gray-500">
                                 ({selected.symbol})
                              </span>
                           </div>
                           <div className="flex items-center gap-2 text-sm text-gray-600">
                              <span>{selected.exchange_name || selected.exchange}</span>
                              {selected.exchange_country && (
                                 <>
                                    <span>•</span>
                                    <span>{selected.exchange_country}</span>
                                 </>
                              )}
                              {selected.industry_name && (
                                 <>
                                    <span>•</span>
                                    <span>{selected.industry_name}</span>
                                 </>
                              )}
                           </div>
                        </div>
                        <div className="flex flex-col items-end gap-1">
                           <span className="text-xl font-semibold">
                              {selected.currency} {parseFloat(selected.regular_market_price).toLocaleString(undefined, {
                                 minimumFractionDigits: 2,
                                 maximumFractionDigits: 2
                              })}
                           </span>
                           <div className="flex items-center gap-2">
                              <span
                                 className={`font-medium ${parseFloat(selected.regular_market_change) > 0
                                    ? 'text-green-500'
                                    : parseFloat(selected.regular_market_change) < 0
                                       ? 'text-red-500'
                                       : 'text-gray-500'
                                    }`}
                              >
                                 {parseFloat(selected.regular_market_change) > 0 ? '↑' : '↓'}{' '}
                                 {Math.abs(parseFloat(selected.regular_market_change)).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                 })}
                              </span>
                              <span
                                 className={`text-sm ${parseFloat(selected.regular_market_change_percent) > 0
                                    ? 'text-green-500'
                                    : parseFloat(selected.regular_market_change_percent) < 0
                                       ? 'text-red-500'
                                       : 'text-gray-500'
                                    }`}
                              >
                                 ({parseFloat(selected.regular_market_change_percent).toFixed(2)}%)
                              </span>
                           </div>
                        </div>
                     </div>
                     {selected.trade_type && (
                        <div className="mt-2">
                           <span className="text-xs px-2 py-1 bg-gray-200 text-gray-700 rounded-full">
                              {selected.trade_type}
                           </span>
                        </div>
                     )}
                  </div>
               )}
               <input type="hidden" name="stockId" />
            </Form.Item>
            {isAdmin && (
               <Form.Item
                  label={t('common.user')}
                  name="user_id"
                  rules={[
                     {
                        required: true,
                        message: t('validation.userId')
                     }
                  ]}
               >
                  <Select placeholder={t('common.user')}>
                     {users.map((user: any, index: any) => (
                        <Select.Option key={index} value={user.id}>
                           {user.name} ({user.balance})
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            )}
            {isAdmin && (
               <Form.Item label={t('common.historicalPrice')} name="historical_price">
                  <NumericFormat
                     placeholder="0.00"
                     className="ant-input rizzui-input"
                     style={{
                        display: isAdmin ? 'block !important' : 'none !important'
                     }}
                     prefix={selected?.currency == 'EUR' ? '€' : selected?.currency == 'USD' ? '$' : '₺'}
                     decimalSeparator="."
                     thousandSeparator=","
                     customInput={Input}
                     value={selected?.regular_market_price}
                     variant="bordered"
                     size="sm"
                     onValueChange={(value: any) => {
                        console.log(value.floatValue)
                        form.setFieldValue('historical_price', value.floatValue)
                     }}
                     classNames={{
                        input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                     }}
                  />
                  <input type="hidden" name="historical_price" />
               </Form.Item>
            )}

            <Form.Item
               label={t('common.quantity')}
               name="quantity"
               rules={[
                  {
                     required: true,
                     message: t('validation.quantity')
                  }
               ]}
            >
               <Input
                  size="sm"
                  variant="faded"
                  placeholder={t('common.quantity')}
                  classNames={{
                     input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                  }}
               />
            </Form.Item>
            <Button type="submit" color="primary" isLoading={addUserStockLoading}>
               {t('common.buyStock')}
            </Button>
         </Form>
      </div>
   )
}

export default BuyStock
