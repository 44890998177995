import {
   Button,
   CircularProgress,
   Input,
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   Skeleton,
   useDisclosure
} from '@nextui-org/react'
import { IconCalendar, IconPlus, IconQuestionMark, IconSearch } from '@tabler/icons-react'
import { Card, Checkbox, Form, message } from 'antd'
import { STOCK_LIST, STOCK_LIST_URL, STOCK_STORE, STOCK_STORE_URL } from 'api/stock'
import {
   USER_SELL_STOCK_URL,
   USER_STOCK_GROUP_STOCKS_URL,
   USER_STOCK_LIST,
   USER_STOCK_STORE,
   USER_STOCK_STORE_URL
} from 'api/userStock'
import ReactSelect from 'components/Select'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Text } from 'rizzui'

function SellStock() {
   const { t } = useTranslation()
   const [form] = Form.useForm()
   const [messageApi, contextHolder] = message.useMessage()
   const key = 'updatable'

   const [getStockQuery, { data: stockData, loading, error }] = useFetch<any>()
   const [getStockListQuery, { data: stockListData, loading: searchLoading }] = useFetch<any>()

   const [addNewStockMutation, { data: addNewStockData, loading: addNewStockLoading }] = useFetch<any>()

   const [addUserStockMutation, { data: addUserStockData, loading: addUserStockLoading }] = useFetch<any>()

   const [data, setData] = useState<any>([])

   const [selected, setSelected] = useState<any>(null)

   const [modal, setModal] = useState(false)

   const [search, setSearch] = useState('')

   const [stockRow, setStockRow] = useState<any>(null)

   const [getUserStockByStockIdQuery, { data: userStockData, loading: userStockLoading }] = useFetch<any>()

   const handleFinish = (values: any) => {
      console.log(values)
      if (!stockRow) {
         messageApi.open({
            type: 'error',
            content: t('validation.stockId'),
            duration: 2
         })
         return
      } else if (values.quantity > stockRow?.quantity) {
         message.error(t('validation.maxQuantity'))
         return
      } else {
         //@ts-ignore
         addUserStockMutation(USER_STOCK_STORE, {
            endPoint: USER_SELL_STOCK_URL,
            method: 'POST',
            body: {
               stockId: stockRow?.id,
               quantity: values.quantity
            }
         })
      }
   }

   useEffect(() => {
      //@ts-ignore
      getStockQuery(STOCK_LIST, {
         endPoint: 'user' + '/all-stock'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      getStockListQuery(STOCK_LIST, {
         endPoint: 'stocks' + '/search-stock?query=' + search
      })
   }, [search])

   useEffect(() => {
      if (stockData) {
         const newData = stockData?.data?.map((stock: any, index: any) => ({
            value: stock.stock_id,
            label:
               stock.stock.short_name +
               ' (' +
               stock.stock.symbol +
               ')' +
               ' | ' +
               stock.stock.regular_market_price +
               ' | ' +
               stock.stock.regular_market_change +
               ' | ' +
               stock.stock.regular_market_change_percent
         }))

         setData(newData)
      }
   }, [stockData])

   useEffect(() => {
      if (addUserStockLoading) {
         message.loading(t('messages.stockSelling'))
      } else {
         message.destroy()
      }
   }, [])

   useEffect(() => {
      if (addUserStockData) {
         onClose()
         messageApi.open({
            type: addUserStockData.status == true ? 'success' : 'error',
            content: t('messages.stockSoldSuccessfully'),
            duration: 2
         })
         form.resetFields()
      }
   }, [addUserStockData])

   const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

   const [sellModalForm] = Form.useForm()

   return (
      <div>
         {contextHolder}
         <Modal
            backdrop="blur"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={true}
            size="3xl"
            onClose={() => {
               setStockRow(null)
               sellModalForm.resetFields()
               form.resetFields()
            }}
         >
            <ModalContent>
               {onClose => (
                  <Form form={sellModalForm} onFinish={handleFinish} layout="vertical">
                     <ModalHeader className="flex flex-col gap-1">{t('common.sellStock')}</ModalHeader>
                     <ModalBody>
                        {stockRow?.id && (
                           <Alert
                              color="info"
                              className="border-2 border-blue-200 bg-blue-50"
                           >
                              <div className="space-y-4">
                                 <div className="flex items-center justify-between">
                                    <Text className="text-xl font-bold">
                                       {stockRow?.stock?.short_name}
                                       <span className="ml-2 text-gray-600">({stockRow?.stock?.symbol})</span>
                                    </Text>
                                    <div className="flex items-center gap-2">
                                       <span className="text-gray-600">{stockRow?.stock?.exchange_name}</span>
                                       <span className="px-2 py-1 text-xs rounded-full bg-gray-200">
                                          {stockRow?.stock?.currency}
                                       </span>
                                    </div>
                                 </div>

                                 <div className="grid grid-cols-2 gap-4 p-3 bg-white rounded-lg">
                                    <div className="space-y-2">
                                       <Text className="text-gray-600">{t('common.quantity')}</Text>
                                       <Text className="text-2xl font-bold">{stockRow?.quantity}</Text>
                                    </div>

                                    <div className="space-y-2">
                                       <Text className="text-gray-600">{t('common.currentPrice')}</Text>
                                       <Text className="text-2xl font-bold">
                                          {stockRow?.stock?.regular_market_price}
                                       </Text>
                                    </div>

                                    <div className="space-y-2">
                                       <Text className="text-gray-600">{t('common.totalValue')}</Text>
                                       <Text className="text-2xl font-bold text-blue-600">
                                          {(stockRow?.quantity * stockRow?.stock?.regular_market_price).toFixed(2)}
                                       </Text>
                                    </div>

                                    <div className="space-y-2">
                                       <Text className="text-gray-600">{t('common.change')}</Text>
                                       <Text className={`text-lg font-semibold ${Number(stockRow?.stock?.regular_market_change) > 0
                                          ? 'text-green-500'
                                          : 'text-red-500'
                                          }`}>
                                          {stockRow?.stock?.regular_market_change}
                                          ({stockRow?.stock?.regular_market_change_percent}%)
                                       </Text>
                                    </div>
                                 </div>

                                 <div className="flex items-center gap-2 text-sm text-gray-500">
                                    <IconCalendar size={16} />
                                    {moment(stockRow?.created_at).format('DD.MM.YYYY HH:mm')}
                                 </div>
                              </div>
                           </Alert>
                        )}
                        <Form.Item
                           label={t('common.quantity')}
                           name="quantity"
                           rules={[
                              {
                                 required: true,
                                 message: t('validation.quantity')
                              },
                              {
                                 pattern: /^[0-9]*$/,
                                 message: t('validation.quantity')
                              },
                              {
                                 max: stockRow?.quantity,
                                 message: t('validation.maxQuantity')
                              }
                           ]}
                        >
                           <Input
                              size="sm"
                              variant="faded"
                              placeholder={t('common.quantity')}
                              classNames={{
                                 input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                              }}
                           />
                        </Form.Item>
                        <Form.Item name="stockId">
                           <div style={{ maxHeight: 500, overflow: 'scroll' }} className="p-0">
                              <div>
                                 {userStockLoading &&
                                    Array.from(Array(10).keys()).map((item, index) => (
                                       <div className=" w-full flex items-center gap-3">
                                          <div className="w-full flex flex-col gap-2">
                                             <Skeleton className="h-3 w-full rounded-lg" />
                                             <Skeleton
                                                className="h-3 w-full mt-2 rounded-lg"
                                                style={{ marginTop: 5 }}
                                             />
                                          </div>
                                       </div>
                                    ))}
                              </div>
                              {userStockData &&
                                 !userStockLoading &&
                                 userStockData?.data
                                    ?.filter((item: any) => item.quantity > 0)
                                    .map((stock: any, index: any) => (
                                       <div
                                          className={`
                        ${stockRow?.id == stock.id ? 'bg-blue-50 border-blue-200' : 'bg-white'}
                        flex flex-col md:flex-row items-start md:items-center justify-between 
                        gap-3 p-4 border rounded-lg mb-2 cursor-pointer 
                        hover:bg-blue-50 hover:border-blue-200 transition-all duration-200
                      `}
                                          onClick={() => {
                                             setStockRow(stock)
                                             sellModalForm.setFieldsValue({
                                                stockId: stock.id
                                             })
                                          }}
                                          key={index}
                                       >
                                          <div className="flex flex-col flex-grow gap-2">
                                             <div className="flex items-center justify-between">
                                                <div>
                                                   <span className="text-lg font-bold text-gray-900">
                                                      {stock.stock.short_name}
                                                   </span>
                                                   <span className="ml-2 text-sm text-gray-500">
                                                      ({stock.stock.symbol})
                                                   </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                   <span className="px-2 py-1 text-xs rounded-full bg-gray-100 text-gray-600">
                                                      {stock.stock.currency}
                                                   </span>
                                                   <span className="text-xs text-gray-500">
                                                      {stock.stock.exchange_name}
                                                   </span>
                                                </div>
                                             </div>

                                             <div className="flex flex-wrap gap-4">
                                                <div className="flex items-center gap-2">
                                                   <span className="text-gray-500">{t('common.currentPrice')}:</span>
                                                   <span className="font-semibold">
                                                      {stock.stock.regular_market_price}
                                                   </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                   <span className={`font-medium ${Number(stock.stock.regular_market_change) > 0
                                                      ? 'text-green-500'
                                                      : 'text-red-500'
                                                      }`}>
                                                      {stock.stock.regular_market_change}
                                                      <span className="ml-1">
                                                         ({stock.stock.regular_market_change_percent}%)
                                                      </span>
                                                   </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                   <span className="text-gray-500">{t('common.quantity')}:</span>
                                                   <span className="font-bold text-blue-600">
                                                      {stock.quantity}
                                                   </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                   <span className="text-gray-500">{t('common.totalValue')}:</span>
                                                   <span className="font-bold text-blue-600">
                                                      {(stock.quantity * stock.stock.regular_market_price).toFixed(2)}
                                                   </span>
                                                </div>
                                             </div>

                                             <div className="flex items-center gap-2 text-sm text-gray-400">
                                                <IconCalendar size={16} />
                                                {moment(stock.created_at).format('DD.MM.YYYY HH:mm')}
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                           </div>
                        </Form.Item>
                     </ModalBody>
                     <ModalFooter>
                        <Button
                           color="danger"
                           onClick={() => {
                              onClose()
                           }}
                        >
                           {t('buttons.cancel')}
                        </Button>
                        <Button color="primary" type="submit">
                           {t('buttons.confirm')}
                        </Button>
                     </ModalFooter>
                  </Form>
               )}
            </ModalContent>
         </Modal>
         <Form form={form} layout="vertical" onFinish={() => { }}>
            <Form.Item
               label={t('common.sellStock')}
               name="stock"
               rules={[
                  {
                     required: true,
                     message: t('validation.stockId')
                  }
               ]}
            >
               <ReactSelect

                  options={data?.map((stock: any) => ({
                     value: stock.value,
                     label: (
                        <div className="flex px-2 items-center justify-between w-full py-2">
                           <div className="flex flex-col">
                              <span className="font-semibold">
                                 {stock.label.split('|')[0]}
                              </span>
                              <div className="flex items-center gap-2 text-sm">
                                 <span className="px-2 py-0.5 text-xs rounded-full bg-gray-100">
                                    {stockData.data.find((s: any) => s.stock_id === stock.value)?.stock?.currency}
                                 </span>
                                 <span className="text-gray-500">
                                    {stockData.data.find((s: any) => s.stock_id === stock.value)?.stock?.exchange_name}
                                 </span>
                              </div>
                           </div>
                           <div className="flex items-center gap-4">
                              <div className="text-right">
                                 <div className="font-medium">
                                    {stock.label.split('|')[1]}
                                 </div>
                                 <div className={`text-sm ${Number(stock.label.split('|')[2]) > 0
                                    ? 'text-green-500'
                                    : 'text-red-500'
                                    }`}>
                                    {stock.label.split('|')[2]} ({stock.label.split('|')[3]})
                                 </div>
                              </div>
                              <div className="text-right min-w-[80px]">
                                 <div className="text-sm text-gray-500">{t('common.quantity')}</div>
                                 <div className="font-bold text-blue-600">
                                    {stockData.data.find((s: any) => s.stock_id === stock.value)?.quantity}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )
                  }))}
                  loading={loading}
                  onChange={(value: any) => {
                     console.log(value)
                     form.setFieldsValue({
                        stock: value.value
                     })
                     onOpen()
                     setSelected(stockData.data.find((stock: any) => stock.id === value.value))
                     //@ts-ignore
                     getUserStockByStockIdQuery(USER_STOCK_LIST, {
                        endPoint: USER_STOCK_GROUP_STOCKS_URL + '?stockId=' + value.value
                     })
                  }}
                  value={form.getFieldValue('stock')}
                  classNames={{
                     control: (state: any) =>
                        `!min-h-[60px] border-1 px-2 !bg-white ${state.isFocused ? '!border-blue-400' : '!border-gray-200'}`,
                     option: (state: any) =>
                        `!py-2 ${state.isFocused ? '!bg-blue-50' : '!bg-white'} ${state.isSelected ? '!bg-blue-100' : ''
                        }`,
                     menu: () => "!border !border-gray-200 !shadow-lg",
                     placeholder: () => "!text-gray-400"
                  }}
                  placeholder={t('common.selectStock')}
                  noOptionsMessage={() => t('common.noOptions')}
                  loadingMessage={() => t('common.loading')}
               />

               <input type="hidden" name="stockId" />
            </Form.Item>
         </Form>
      </div>
   )
}

export default SellStock
