import axios from "axios";
import { FetchProps } from "./interface";

export const STOCK_LIST_URL = '/stocks?page=:page';

export const STOCK_STORE_URL = '/stocks';

export const STOCK_UPDATE_URL = '/stocks';

export const STOCK_LIST = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const STOCK_STORE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const STOCK_UPDATE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const STOCK_DELETE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const STOCK_SHOW = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}