import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../config/routes";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../../components/page-header";
import {
  Form,
  Modal,
  Switch,
  Table,
  TableColumnProps,
  Tag,
  message,
} from "antd";
import { Button } from "../../../../components/ui/button";
import { Card } from "@nextui-org/react";
import useFetch from "../../../../hooks/use-fetch";
import { ADD_LOCALE, GET_LOCALES } from "../../../../api/locale";
import { Input } from "rizzui";

const LanguageSettings = () => {
  const { t } = useTranslation();
  const pageHeader = {
    title: "Languages",
    breadcrumb: [
      {
        href: routes.dashboard,
        name: t("aside.dashboard"),
      },
      {
        href: routes.language.list,
        name: "Languages"
      },
    ],
  };
  const navigate = useNavigate();

  const columns: TableColumnProps<any>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "locale",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <>
          <span>{record.locale}</span>
        </>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (text, record) => (
        <>
          <span>{record.code}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <>
          <Tag color={record.is_default === 1 ? "green" : "red"}>
            {record.is_default === 1
              ? "Default"
              : "Not Default" }
          </Tag>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Link to={routes.language.edit(record.id)}>
            <Button color="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];

  const [fetchData, { data, loading, error }] = useFetch<any>();

  useEffect(() => {
    //@ts-ignore
    fetchData(GET_LOCALES, {
      endPoint: "/languages",
    });
  }, []);

  const [addLocaleModal, addLocaleOnOpenModal] = useState(false);

  const [form] = Form.useForm();

  const [
    addLocaleMutation,
    { data: addLocaleData, loading: addLocaleLoading, error: addLocaleError },
  ] = useFetch<any>();

  const onFinish = (values: any) => {
    const data = {
      locale: values.locale,
      code: values.code,
      is_default: values.is_default,
    };
    //@ts-ignore
    addLocaleMutation(ADD_LOCALE, {
      endPoint: `languages`,
      body: data,
    });
  };

  useEffect(() => {
    if (addLocaleData) {
      message.success(t("messages.add_success"));
      navigate(routes.language.edit(addLocaleData?.data.id));
    }
  }, [addLocaleData]);

  useEffect(() => {
    if (addLocaleError) {
      message.error('Locale already exists');
    }
  }, [addLocaleError]);

  return (
    <div>
      <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
        <Button
          onClick={() => addLocaleOnOpenModal(true)}
          color="primary"
        >
          Add Language
        </Button>
      </PageHeader>

      <Modal
        open={addLocaleModal}
        footer={null}
        onCancel={() => addLocaleOnOpenModal(false)}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="locale" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="code" label="Code">
            <Input />
          </Form.Item>
          <Form.Item name="is_default" label="Default" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Button type="submit" isLoading={addLocaleLoading} color="primary">
            Add
          </Button>
        </Form>
      </Modal>

      <Card>
        <Table
          columns={columns}
          dataSource={data?.data}
          rowKey="id"
          loading={loading}
        />
      </Card>
    </div>
  );
};

export default LanguageSettings;
