import axios from 'axios'
import { FetchProps } from './interface'

export const APP_LIST_URL = '/app-settings'

export const APP_STORE_URL = '/brand'

export const APP_UPDATE_URL = '/update-app-settings'

export const APP_LIST = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .get(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const APP_STORE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .post(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const APP_UPDATE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .post(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const APP_DELETE = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .delete(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}

export const APP_SHOW = async (props: FetchProps) => {
   const { endPoint, body } = props as any
   return await axios
      .get(endPoint, body)
      .then(({ data }) => {
         return { data }
      })
      .catch(error => {
         return { error }
      })
}
