import { Card } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "rizzui";
import cn from "utils/class-names";
import BuyTable from "./Table/Buy";
import SellTable from "./Table/Sell";
import useFetch from "hooks/use-fetch";
import { Skeleton } from "@nextui-org/react";
import {
  USER_TRANSACTION_LIST,
  USER_TRANSACTION_LIST_URL,
} from "api/userTransaction";
import { IconArrowDown, IconArrowUp, IconCalendar, IconCurrencyEuro } from '@tabler/icons-react'
import moment from 'moment'

export default function Transaction() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const [
    userTransactionsQuery,
    {
      data: userTransactions,
      loading: isUserTransactionsLoading,
      error: userTransactionsError,
    },
  ] = useFetch<any>();
  const RenderTabHeader = () => {
    return (
      <div className="flex gap-2">
        <TabList className="flex justify-start space-x-8 border-b border-b-gray-300">
          <Tab
            className={({ selected }: any) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.buyStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
          <Tab
            className={({ selected }) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.sellStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
        </TabList>
      </div>
    );
  };

  useEffect(() => {
    //@ts-ignore
    userTransactionsQuery(USER_TRANSACTION_LIST, {
      endPoint: USER_TRANSACTION_LIST_URL.replace(":page", "1"),
    });
  }, []);

  return (
    <Tabs defaultIndex={activeTab} onChange={setActiveTab}>
      <Card title={t("common.transactionHistory")} extra={<RenderTabHeader />}>
        <TabPanels>
          <TabPanel className="py-2 text-sm leading-6 text-gray-600">
            {isUserTransactionsLoading ? (
              <div className="space-y-4">
                {Array.from(Array(3).keys()).map((item, index) => (
                  <div className="animate-pulse bg-gray-50 rounded-lg p-4" key={index}>
                    <div className="flex justify-between items-center">
                      <div className="space-y-3">
                        <div className="h-4 bg-gray-200 rounded w-48"></div>
                        <div className="h-3 bg-gray-200 rounded w-32"></div>
                      </div>
                      <div className="h-10 bg-gray-200 rounded w-24"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="space-y-4">
                {userTransactions?.data?.data
                  .filter((item: any) => item.type === "buy")
                  .map((transaction: any, index: number) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg border border-gray-100 p-4 hover:border-blue-100 transition-all duration-200"
                    >
                      <div className="flex justify-between items-start">
                        <div className="space-y-3">
                          <div className="flex items-center gap-3">
                            <h3 className="text-lg font-semibold">
                              {transaction.stock.short_name}
                            </h3>
                            <span className="text-sm px-2 py-1 rounded-full bg-blue-50 text-blue-600">
                              {transaction.stock.symbol}
                            </span>
                          </div>

                          <div className="flex items-center gap-4 text-sm text-gray-500">
                            <div className="flex items-center gap-1">
                              <IconCurrencyEuro size={16} />
                              <span>{transaction.stock.exchange_name}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <IconCalendar size={16} />
                              {moment(transaction.created_at).format('DD.MM.YYYY HH:mm')}
                            </div>
                          </div>

                          <div className="flex gap-4">
                            <div className="px-3 py-1 rounded-lg bg-gray-50">
                              <span className="text-gray-500 text-sm">{t('common.quantity')}:</span>
                              <span className="ml-2 font-semibold">{transaction.quantity}</span>
                            </div>
                            <div className="px-3 py-1 rounded-lg bg-gray-50">
                              <span className="text-gray-500 text-sm">{t('common.price')}:</span>
                              <span className="ml-2 font-semibold">
                                {transaction.currency || transaction.stock.currency} {parseFloat(transaction.price).toLocaleString()}
                              </span>
                            </div>
                            <div className="px-3 py-1 rounded-lg bg-gray-50">
                              <span className="text-gray-500 text-sm">{t('common.total')}:</span>
                              <span className="ml-2 font-semibold">
                                {transaction.currency || transaction.stock.currency} {parseFloat(transaction.total).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col items-end gap-2">
                          <div className="flex items-center gap-2">
                            <span className={`text-sm font-medium ${parseFloat(transaction.stock.regular_market_change) > 0
                                ? 'text-green-500'
                                : 'text-red-500'
                              }`}>
                              {parseFloat(transaction.stock.regular_market_change) > 0 ? (
                                <IconArrowUp size={16} className="inline" />
                              ) : (
                                <IconArrowDown size={16} className="inline" />
                              )}
                              {Math.abs(parseFloat(transaction.stock.regular_market_change)).toFixed(2)}
                              <span className="ml-1">
                                ({transaction.stock.regular_market_change_percent}%)
                              </span>
                            </span>
                          </div>
                          <span className="text-xs px-2 py-1 rounded-full bg-green-50 text-green-600">
                            {t('common.buyTransaction')}
                          </span>
                        </div>
                      </div>

                      {transaction.stock.industry_name && (
                        <div className="mt-3 pt-3 border-t border-gray-100">
                          <span className="text-xs px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                            {transaction.stock.industry_name}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </TabPanel>
          <TabPanel className="py-2 text-sm leading-6 text-gray-600">
            {isUserTransactionsLoading ? (
              <div>
                {Array.from(Array(10).keys()).map((item, index) => (
                  <div
                    className="max-w-[300px] w-full flex items-center gap-3"
                    key={index}
                  >
                    <div>
                      <Skeleton className="flex rounded-full w-12 h-12" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                      <Skeleton className="h-3 w-4/5 rounded-lg" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <BuyTable
                  data={userTransactions?.data?.data.filter(
                    (item: any) => item.type === "sell"
                  )}
                />
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </Card>
    </Tabs>
  );
}
