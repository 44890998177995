import React, { useEffect } from 'react'
import { Card, Chip } from '@nextui-org/react'
import Sidebar from './Sidebar'
import HamburgerButton from './HamerburgerButton'
import { useIsMounted } from 'hooks/use-is-mounted'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import cn from 'utils/class-names'
import { Link } from 'react-router-dom'

import ProfileMenu from './profile-menu'
import { useSelector } from 'react-redux'
import { MoneyFormat } from 'hooks/currency'
import pusherJs from 'pusher-js'
import LanguageList from './Language'

interface Props {
   toggleSidebar: any
}

function HeaderMenuRight() {
   const user = useSelector((state: any) => state.user)

   return (
      <div className="ms-auto flex shrink-0 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
         <LanguageList />
         <Chip>
            Balance : <span className="font-semibold">{MoneyFormat(parseFloat(user?.user?.balance) ?? 0)}</span>
         </Chip>
         <ProfileMenu
            buttonClassName="w-auto sm:w-auto p-1 border border-gray-300"
            avatarClassName="!w-7 !h-7 sm:!h-8 sm:!w-8"
         />
      </div>
   )
}

export default function Header() {
   const isMounted = useIsMounted()
   const windowScroll = useWindowScroll()
   const [open, setOpen] = React.useState(false)
   const [foreigns, setForeigns] = React.useState<any>([])
   useEffect(() => {
      const pusher = new pusherJs('614ec570899f6dada256', {
         cluster: 'eu'
      })
      const channel = pusher.subscribe('currency-update')

      channel.bind('currency-update', function (data: any) {
         localStorage.setItem('currency', JSON.stringify(data.currency))
         setForeigns(data.currency)
      })
   }, [])
   return (
      <header
         className={cn(
            'sticky top-0 z-50 flex items-center justify-between bg-gray-0/80 px-4 py-4 backdrop-blur-xl dark:bg-gray-50/50 md:px-5 lg:px-6 2xl:py-5 2xl:pl-6 3xl:px-8',
            ((isMounted && windowScroll.y) as number) > 2 ? 'card-shadow' : ''
         )}
      >
         <div className="flex w-full max-w-2xl items-center xl:w-auto">
            <HamburgerButton view={<Sidebar className="static w-full 2xl:w-full" />} />
            <Link aria-label="Site Logo" to={'/'} className="me-4 w-9 shrink-0 text-gray-900 lg:me-5 xl:hidden">
               {/* <Logo iconOnly={true} /> */}
            </Link>
            {/* <SearchWidget
              icon={<PiMagnifyingGlassDuotone className="h-[20px] w-[20px]" />}
              className={cn(
                'text-gray-700 hover:text-gray-900 focus-visible:outline-0 active:translate-y-0 xl:border-0 xl:p-0 xl:shadow-none xl:backdrop-blur-none xl:hover:border-0 xl:hover:outline-0 xl:focus:outline-0 xl:focus-visible:outline-0 [&_.magnifying-glass]:me-0 [&_.placeholder-text]:hidden [&_.search-command]:ms-2 [&_.search-command]:hidden [&_.search-command]:lg:text-gray-0'
              )}
            /> */}
         </div>

         <div className="hidden items-center gap-3 xl:flex">
            <Link
               aria-label="Site Logo"
               to={'/'}
               className="me-4 hidden w-[155px] shrink-0 text-gray-900 lg:me-5 xl:block"
            >
               {/* <Logo className="max-w-[155px]" /> */}
            </Link>
            {/* <HeaderMenusLeft /> */}
         </div>
         <div className="flex w-full items-center gap-5 xl:w-auto 3xl:gap-6">
            <HeaderMenuRight />
         </div>
      </header>
   )
}
