import { TableColumnProps } from 'antd'
import TableComponents from 'components/Table'
import { MoneyFormat } from 'hooks/currency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ReferralProps {
   data: any
}

function Referral(props: ReferralProps) {
   const { t } = useTranslation()
   const columns: TableColumnProps<any>[] = [
      {
         title: t('common.transactionType'),
         dataIndex: 'type',
         key: 'type',
         className: 'capitalize',
         render: (type: any) => {
            return <div className="flex items-center gap-3">{type}</div>
         }
      },
      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat(amount)}</div>
         }
      },
      {
         title: t('common.description'),
         dataIndex: 'description',
         key: 'description'
      },
      {
         title: t('common.createdAt'),
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (createdAt: any) => {
            return <div className="flex items-center gap-3">{moment(createdAt).format('DD.MM.YYYY')}</div>
         }
      }
   ]
   return (
      <div>
         <TableComponents columns={columns} data={props.data} pagination={false} loading={false} />
      </div>
   )
}

export default Referral
