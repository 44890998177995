import moment from 'moment'
import React from 'react'

interface ActivitiesProps {
   data: any
}

function Activities(props: ActivitiesProps) {
   return (
      <div>
         {props?.data?.map((item: any, index: number) => (
            <li className="mb-10 ms-4" key={index}>
               <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
               <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                  {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}
               </time>

               <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{item.activity}</p>
            </li>
         ))}
      </div>
   )
}

export default Activities
