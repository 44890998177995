import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { routes } from '../../../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import PageHeader from '../../../../components/page-header'
import { Form, Switch, Table, TableColumnProps, Tag, message } from 'antd'
import { Button } from '../../../../components/ui/button'
import { Card } from '@nextui-org/react'
import useFetch from '../../../../hooks/use-fetch'
import { GET_LOCALE, GET_LOCALES, UPDATE_LOCALE } from '../../../../api/locale'
import LanguageForm from './Form'
import axios from 'axios'
import { Input } from 'rizzui'

const EditLanguage = () => {
   const { t } = useTranslation()
   const { id } = useParams<any>()
   const pageHeader = {
      title: 'Edit Language',
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.language.list,
            name: 'Languages'
         }
      ]
   }
   const navigate = useNavigate()

   const [form] = Form.useForm()

   const [row, { data: rowData, loading: rowLoading, error: rowError }] = useFetch<any>()

   const [updateLocaleMutation, { loading: updateLoading, error: updateError, data: updateData }] = useFetch<any>()

   useEffect(() => {
      if (rowData) {
         Object.keys(rowData?.data).map(key => {
            Object.keys(rowData?.data[key]).map(key2 => {
               form.setFieldsValue({
                  [key2]: rowData?.data[key][key2]
               })
            })
         })

         form.setFieldsValue({
            locale: rowData?.data?.locale,
            code: rowData?.data?.code,
            is_default: rowData?.data?.is_default === 1
         })
      }
   }, [rowData])

   useEffect(() => {
      // @ts-ignore
      row(GET_LOCALE, {
         endPoint: `languages/${id}`
      })
   }, [id])

   const onFinish = async (values: any) => {
      console.log('sa')
      const data = {
         locale: values.locale,
         code: values.code,
         is_default: values.is_default,
         data: {
            ...values
         }
      }
      //@ts-ignore
      updateLocaleMutation(UPDATE_LOCALE, {
         endPoint: `languages/${id}`,
         body: data
      })
   }

   useEffect(() => {
      if (updateError) {
      }
   }, [updateError])

   useEffect(() => {
      if (updateLoading) {
      }
   }, [updateLoading])

   useEffect(() => {
      if (updateData) {
         message.success(t('messages.update_success'))
      }
   }, [updateData])

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}></PageHeader>

         <Form
            form={form}
            layout="vertical"
            name="language-create"
            onFinish={onFinish}
            onFinishFailed={e => {
               console.log(e)
            }}
         >
            <Button color="primary" type="submit" isLoading={updateLoading}>
               Save
            </Button>
            <Form.Item name="locale" label="Name">
               <Input />
            </Form.Item>
            <Form.Item name="code" label="Code">
               <Input />
            </Form.Item>
            <Form.Item name="is_default" label="Default" valuePropName="checked">
               <Switch />
            </Form.Item>
            <LanguageForm />
         </Form>
      </div>
   )
}

export default EditLanguage
