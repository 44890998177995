import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Input } from 'rizzui'

interface Props {
   onFinish: any
   initialValues?: any
   isLoading?: boolean
   form: any
}

const UserForm = (props: Props) => {
   const { t } = useTranslation()

   useEffect(() => {
      if (props.initialValues?.name !== '') {
         const user = props.initialValues
         props.form.setFieldsValue({
            name: user?.name,
            email: user?.email,
            last_name: user?.last_name,
            user_type: user?.user_type,
            balance: user?.balance
         })
      }
   }, [props.initialValues])

   return (
      <div>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.name_required')
               }
            ]}
            label={t('common.name')}
            name="name"
         >
            <Input className="ant-input rizzui-input" />
         </Form.Item>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.last_name_required')
               }
            ]}
            label={t('common.last_name')}
            name="last_name"
         >
            <Input className="ant-input rizzui-input" />
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.email_required')
               },
               {
                  type: 'email',
                  message: t('validation.email_format')
               }
            ]}
            label={t('common.email')}
            name="email"
         >
            <Input className="ant-input rizzui-input" type="text" />
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: props.initialValues?.name ? false : true,
                  message: t('validation.password')
               }
            ]}
            label={t('common.password')}
            name="password"
         >
            <Input className="ant-input rizzui-input" type="text" />
         </Form.Item>

         <Form.Item
            label="User Type"
            name="user_type"
            rules={[
               {
                  required: true,
                  message: t('validation.role_required')
               }
            ]}
         >
            <Select
               placeholder="User Type"
               defaultValue={props.initialValues?.user_type}
               className="w-full"
               // defaultValue={props.initialValues?.user_type}
               // onChange={(value) => {
               //   form.setFieldsValue({ user_type: value });
               // }}
            >
               <Select.Option value="admin">Admin</Select.Option>
               <Select.Option value="user">User</Select.Option>
            </Select>
         </Form.Item>
         <Form.Item
            name="balance"
            rules={[
               {
                  required: true,
                  message: t('validation.balance_required')
               }
            ]}
            label={t('common.balance')}
         >
            <NumericFormat
               placeholder="0.00"
               className="rizzui-input"
               prefix="€"
               decimalSeparator="."
               thousandSeparator=","
               customInput={Input}
               value={props.initialValues?.balance}
               onValueChange={(value: any) => {
                  props.form.setFieldValue('balance', value.floatValue)
                  console.log(value.floatValue)
               }}
            />
            <input className="ant-input rizzui-input" type="hidden" name="balance" />
         </Form.Item>
      </div>
   )
}

export default UserForm
