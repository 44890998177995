import { Button } from "@nextui-org/react";
import { Card, Col, Row } from "antd";
import PageHeader from "components/page-header";
import { routes } from "config/routes";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "rizzui";
import cn from "utils/class-names";
import BuyStock from "./BuyStock";
import SellStock from "./SellStock";
import Transaction from "./Transaction";
import Activities from "./Activity";
import TradingViewWidget from "../../../components/layouts/TradingMarketing";
import StatCards from "../home/Summary";

const LiveTrading = () => {
  const { t } = useTranslation();
  const pageHeader = {
    title: t("aside.liveTrading"),
    breadcrumb: [
      {
        href: routes.dashboard,
        name: t("aside.dashboard"),
      },
      {
        href: routes.liveTrading,
        name: t("aside.liveTrading"),
      },
    ],
  };

  const [activeTab, setActiveTab] = useState(0);

  const RenderTabHeader = () => {
    return (
      <div className="flex gap-2">
        <TabList className="flex justify-start space-x-8 border-b border-b-gray-300">
          <Tab
            className={({ selected }) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.buyStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
          <Tab
            className={({ selected }) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.sellStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
        </TabList>
      </div>
    );
  };

  return (
    <div>
      <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
        <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
      </PageHeader>

      <div className="@container">
        <StatCards />
        <Row gutter={24} className={"mt-5"}>
          <Col span={14}>
            <Tabs defaultIndex={activeTab} onChange={setActiveTab}>
              <Card title={t("aside.liveTrading")} extra={<RenderTabHeader />}>
                <TabPanels>
                  <TabPanel className="py-2 text-sm leading-6 text-gray-600">
                    <BuyStock />
                  </TabPanel>
                  <TabPanel className="py-2 text-sm leading-6 text-gray-600">
                    <SellStock />
                  </TabPanel>
                </TabPanels>
              </Card>
            </Tabs>
          </Col>
          <Col span={10}>
            <Transaction />
          </Col>
          <Col span={24} className="mt-2">
            List My Stocks With Status Area
          </Col>

        </Row>
      </div>
    </div>
  );
};

export default LiveTrading;
