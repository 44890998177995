import { TableColumnProps } from 'antd'
import TableComponents from 'components/Table'
import { MoneyFormat2 } from 'hooks/currency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IposProps {
   data: any
}

function Ipos(props: IposProps) {
   const { t } = useTranslation()
   const columns: TableColumnProps<any>[] = [
      {
         title: t('common.contactDate'),
         dataIndex: 'contact_date',
         key: 'contact_date',
         render: (contact_date: any) => {
            return <div className="flex items-center gap-3">{moment(contact_date).format('DD/MM/YYYY')}</div>
         }
      },
      {
         title: t('common.iposWkn'),
         dataIndex: 'id',
         key: 'id',
         render: (pieces: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span>{record.name}</span>
                  <span>{record.wkn}</span>
               </div>
            )
         }
      },

      {
         title: t('common.pieces'),
         dataIndex: 'pieces',
         key: 'pieces',
         render: (pieces: any) => {
            return <div className="flex items-center gap-3">{pieces}</div>
         }
      },
      {
         title: t('common.price'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(price)}</div>
         }
      },

      {
         title: t('common.total'),
         dataIndex: 'total',
         key: 'total',
         render: (total: any, record: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(record.price * record.pieces)}</div>
         }
      },

      {
         title: t('common.bookBuilding'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any, record: any) => {
            return <div className="flex items-center gap-3">{record.bookBuilding}</div>
         }
      }
   ]
   return (
      <div>
         <TableComponents columns={columns} data={props.data} pagination={false} loading={false} />
      </div>
   )
}

export default Ipos
