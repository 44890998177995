import { Form } from 'antd'
import React, { useEffect } from 'react'
import { Input } from 'rizzui'

interface Props {
    values: any
    form: any
}

function EditForm(props: Props) {
    const { values, form } = props

    useEffect(() => {
        form.setFieldsValue({
            regular_market_price: values.regular_market_price
        })
    }, [values])

    return (
        <div>
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: 'Please input your regular market price!',
                    },
                ]}
                name="regular_market_price"
                label="Regular Market Price"
            >
                <Input
                    type="number"
                    placeholder="Regular Market Price"
                    className="ant-input rizzui-input"
                />
            </Form.Item>
        </div>
    )
}

export default EditForm
