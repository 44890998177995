import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconArrowDown, IconArrowUp, IconCalendar, IconCurrencyEuro } from '@tabler/icons-react';

interface TableProps {
  data: any[];
}

export default function BuyTable(props: TableProps) {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <div className="space-y-4">
      {data?.map((item: any, index: number) => (
        <div
          key={index}
          className="bg-white rounded-lg border border-gray-100 p-4 hover:border-blue-100 transition-all duration-200"
        >
          <div className="flex justify-between items-start">
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <h3 className="text-lg font-semibold">
                  {item.stock.short_name}
                </h3>
                <span className="text-sm px-2 py-1 rounded-full bg-blue-50 text-blue-600">
                  {item.stock.symbol}
                </span>
                <span className={`text-sm px-2 py-1 rounded-full ${item.type === 'buy'
                    ? 'bg-green-50 text-green-600'
                    : 'bg-red-50 text-red-600'
                  }`}>
                  {item.type === 'buy' ? t('common.buy') : t('common.sell')}
                </span>
              </div>

              <div className="flex items-center gap-4 text-sm text-gray-500">
                <div className="flex items-center gap-1">
                  <IconCurrencyEuro size={16} />
                  <span>{item.stock.exchange_name}</span>
                </div>
                <div className="flex items-center gap-1">
                  <IconCalendar size={16} />
                  {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                </div>
              </div>

              <div className="flex gap-4">
                <div className="px-3 py-1 rounded-lg bg-gray-50">
                  <span className="text-gray-500 text-sm">{t('common.quantity')}:</span>
                  <span className="ml-2 font-semibold">{item.quantity}</span>
                </div>
                <div className="px-3 py-1 rounded-lg bg-gray-50">
                  <span className="text-gray-500 text-sm">{t('common.price')}:</span>
                  <span className="ml-2 font-semibold">
                    {item.stock.currency} {parseFloat(item.price).toLocaleString()}
                  </span>
                </div>
                <div className="px-3 py-1 rounded-lg bg-gray-50">
                  <span className="text-gray-500 text-sm">{t('common.total')}:</span>
                  <span className="ml-2 font-semibold">
                    {item.stock.currency} {parseFloat(item.total).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end gap-2">
              <div className="flex items-center gap-2">
                <span className={`text-sm font-medium ${parseFloat(item.stock.regular_market_change) > 0
                    ? 'text-green-500'
                    : 'text-red-500'
                  }`}>
                  {parseFloat(item.stock.regular_market_change) > 0 ? (
                    <IconArrowUp size={16} className="inline" />
                  ) : (
                    <IconArrowDown size={16} className="inline" />
                  )}
                  {Math.abs(parseFloat(item.stock.regular_market_change)).toFixed(2)}
                  <span className="ml-1">
                    ({item.stock.regular_market_change_percent}%)
                  </span>
                </span>
              </div>

              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500">{t('common.user')}:</span>
                <span className="font-medium">{item.user.name}</span>
              </div>
            </div>
          </div>

          {item.stock.industry_name && (
            <div className="mt-3 pt-3 border-t border-gray-100">
              <span className="text-xs px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                {item.stock.industry_name}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
