import axios from "axios";
import { FetchProps } from "./interface";

export const USER_TRANSACTION_LIST_URL = '/user/transactions?page=:page';

export const USER_TRANSACTION_STORE_URL = '/user/transactions';

export const USER_TRANSACTION_UPDATE_URL = '/user/transactions';

export const USER_TRANSACTION_LIST = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const USER_TRANSACTION_STORE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const USER_TRANSACTION_UPDATE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const USER_TRANSACTION_DELETE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const USER_TRANSACTION_SHOW = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}