"use client";

import { routes } from "config/routes";

import { useEffect, useState } from "react";
import PageHeader from "components/page-header";

import { Button, Card, Pagination, Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/use-fetch";
import { USER_STOCK_LIST, USER_STOCK_LIST_URL } from "api/userStock";
import { Link, useParams } from "react-router-dom";
import ListTable from "../my-stocks/Table";
import { Filter } from "../my-stocks/Filter";
import pusherJs from "pusher-js";
import OtherTable from "./OtherTable";

export default function MySellStocksTable() {
  const { t } = useTranslation();
  const [stockData, setStockData] = useState<any>([]);
  const params = useParams<any>();
  const pageHeader = {
    title: t(params.type === "buy" ? "aside.myStocks" : "aside.selledStocks"),
    breadcrumb: [
      {
        href: routes.dashboard,
        name: t("aside.dashboard"),
      },
      {
        href: routes.transcationSell,
        name: t("aside.selledStocks"),
      },
    ],
  };
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [is_anonymous, setIs_anonymous] = useState<any>(null);
  const [complaintType, setComplaintType] = useState("");
  const [rows, { data: rowsData, loading: rowsLoading, error: rowsError }] =
    useFetch<any>();

  useEffect(() => {
    //@ts-ignore
    rows(USER_STOCK_LIST, {
      endPoint:
        USER_STOCK_LIST_URL.replace(":page", page.toString()) +
        "&type=" +
        params.type,
    });
  }, [page, search, params.type]);

  return (
    <>
      <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
        
      </PageHeader>

      <Card className="">
       
        <OtherTable
         
          isLoaded={rowsLoading}
          bottomContent={
            <Pagination
              total={rowsData?.links?.total || 0}
              onChange={(page) => setPage(page)}
              page={page}
              color={"primary"}
            />
          }
          topContent={
            <Filter
              setSearch={setSearch}
              search={search}
              status={status}
              setStatus={setStatus}
              is_anonymous={is_anonymous}
              setIs_anonymous={setIs_anonymous}
              complaintType={complaintType}
              setComplaintType={setComplaintType}
            />
          }
          data={rowsData?.data?.data || []}
        />
      </Card>
    </>
  );
}
