import {
   Button,
   Checkbox,
   Dropdown,
   DropdownItem,
   DropdownMenu,
   DropdownTrigger,
   Input,
   Select,
   SelectItem
} from '@nextui-org/react'
import { IconChevronDown, IconSearch } from '@tabler/icons-react'
import { complaintTypeIds, statusOptions } from '../../../config/api'
import { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface FilterProps {
   search: string
   setSearch?: (search: string) => void
   status?: string
   setStatus?: any
   complaintType?: string
   setComplaintType?: any
   department?: string
   setDepartment?: (department: string) => void
   is_anonymous?: boolean
   setIs_anonymous?: any
   company?: string
}

export const Filter = (props: FilterProps) => {
   const {
      search,
      setSearch,
      status,
      setStatus,
      complaintType,
      setComplaintType,
      department,
      setDepartment,
      is_anonymous,
      setIs_anonymous,
      company
   } = props
   const { t } = useTranslation()
   return (
      <>
         <div className="flex flex-col gap-4">
            <div className="flex gap-3">
               <Input
                  variant="faded"
                  size="sm"
                  isClearable
                  className="w-full sm:max-w-[20%]"
                  placeholder={t('searchInTable')}
                  startContent={<IconSearch />}
                  value={search}
                  classNames={{
                     input: '!border-0 !outline-none focus:ring-0'
                  }}
                  onValueChange={setSearch}
               />
            </div>
         </div>
      </>
   )
}
