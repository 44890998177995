import { routes } from 'config/routes'

import { useEffect, useState } from 'react'
import PageHeader from 'components/page-header'

import { TableColumnProps, Modal, Drawer, Form, message } from 'antd'
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import useFetch from 'hooks/use-fetch'
import { useTranslation } from 'react-i18next'
import { USER_DELETE, USER_LIST, USER_LIST_URL, USER_STORE, USER_STORE_URL, USER_UPDATE } from 'api/user'
import ActionButton from './ActionButton'
import TableComponents from 'components/Table'
import Profile from './Profile/Profile'
import BalanceColumn from './Balance'
import SearchInput from 'components/Input'
import UserForm from './Form'
import { USER_STOCK_GROUP_STOCKS_URL } from 'api/userStock'

export default function UsersTable() {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [selectedUser, setSelectedUser] = useState<any>({})

   const [isOpenDrawer, setIsOpenDrawer] = useState(false)

   const [search, setSearch] = useState('')

   const [addModal, setAddModal] = useState(false)

   const [editModal, setEditModal] = useState(false)

   const [row, setRow] = useState<any>(null)

   const pageHeader = {
      title: t('aside.users'),
      breadcrumb: [
         {
            href: routes.home,
            name: t('dashboard.title')
         },
         {
            href: routes.users.list,
            name: t('aside.users')
         }
      ]
   }

   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()

   const [profileQuery, { data: profileData, loading: profileLoading }] = useFetch<any>()

   const [addUserMutation, { data: addUserData, loading: addUserLoading }] = useFetch<any>()

   const [editUserMutation, { data: editUserData, loading: editUserLoading, error: editUserError }] = useFetch<any>()

   const [deleteUserMutation, { data: deleteUserData, loading: deleteUserLoading, error: deleteUserError }] =
      useFetch<any>()

   const [page, setPage] = useState(1)

   const [addForm] = Form.useForm()
   const [editForm] = Form.useForm()

   const findUser = (id: any) => {
      //@ts-ignore
      profileQuery(USER_LIST, {
         endPoint: USER_STORE_URL + '/' + id + '/edit'
      })
   }

   const onFilter = () => {
      let object: any = {}

      if (search) {
         object.search = search
      }

      let query = new URLSearchParams(object).toString()

      //@ts-ignore
      usersQuery(USER_LIST, {
         endPoint: USER_LIST_URL.replace(':page', (1).toString()) + '&' + query
      })
   }

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.name'),
         dataIndex: 'name',
         key: 'name',
         width: 100
      },
      {
         title: t('common.last_name'),
         dataIndex: 'last_name',
         key: 'last_name',
         width: 100
      },
      {
         title: t('common.email'),
         dataIndex: 'email',
         key: 'email',
         width: 100
      },

      {
         title: t('common.user_type'),
         dataIndex: 'user_type',
         key: 'user_type',
         width: 100
      },

      {
         title: t('common.createdAt'),
         dataIndex: 'created_at',
         key: 'created_at',
         width: 100,
         render: (text: any, record: any) => {
            return <span>{new Date(record.created_at).toLocaleString()}</span>
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'actions',
         key: 'actions',
         width: 100,
         render: (text: any, record: any) => (
            <ActionButton
               deleteButtonClick={() => {
                  Modal.confirm({
                     title: 'Are you sure?',
                     content: 'Are you sure you want to delete this user?',
                     okText: 'Yes',
                     cancelText: 'No',
                     okButtonProps: {
                        loading: deleteUserLoading
                     },
                     onOk: () => {
                        //@ts-ignore
                        deleteUserMutation(USER_DELETE, {
                           endPoint: 'users/' + record.id
                        })
                     }
                  })
               }}
               editButtonClick={() => {
                  setRow(record)
                  setEditModal(true)
               }}
               otherButtonClick={() => {
                  findUser(record.id)
               }}
            />
         )
      },
      {
         title: t('common.balance'),
         dataIndex: 'balance',
         key: 'balance',
         width: 100,
         render: (text: any, record: any) => {
            return (
               <span>
                  <BalanceColumn
                     refetch={() => {
                        onFilter()
                     }}
                     row={record}
                  />
               </span>
            )
         }
      }
   ]

   const handleAddFinish = (values: any) => {
      //@ts-ignore
      addUserMutation(USER_STORE, {
         endPoint: '/users',
         body: values
      })
   }

   const handleUpdateUser = (values: any) => {
      //@ts-ignore
      editUserMutation(USER_UPDATE, {
         endPoint: '/users/' + row?.id,
         body: values
      })
   }

   useEffect(() => {
      if (addUserData) {
         message.success({
            content: t('messages.add_success')
         })
         setRow(null)
         setAddModal(false)
         onFilter()
      }
   }, [addUserData])

   useEffect(() => {
      if (deleteUserData) {
         message.success({
            content: t('messages.delete_success')
         })
         setAddModal(false)
         onFilter()
      }
   }, [deleteUserData])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_LIST, {
         endPoint: USER_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   useEffect(() => {
      if (profileData) {
         setIsOpenDrawer(true)
         setSelectedUser(profileData.data)
         console.log(profileData)
         //@ts-ignore

      }
   }, [profileData])

   useEffect(() => {
      if (editUserData) {
         message.success({
            content: t('messages.update_success')
         })
         setEditModal(false)
         onFilter()
      }
   }, [editUserData])

   return (
      <>
         <Drawer
            placement="bottom"
            title={'Profil Detail ' + selectedUser?.user?.name + ' ' + selectedUser?.user?.last_name}
            height="100%"
            onClose={() => setIsOpenDrawer(false)}
            open={isOpenDrawer}
         >
            <Profile
               user={selectedUser}
               activities={selectedUser?.activities}
               ipos={selectedUser?.ipos}
               investeds={selectedUser?.investeds}
               referrals={selectedUser?.referrals}
               buyStocks={selectedUser?.buyStocks}
               sellStocks={selectedUser?.sellStocks}
               loading={profileLoading}
            />
         </Drawer>
         <Modal open={addModal} title={t('buttons.add')} footer={null} onCancel={() => setAddModal(false)} closable>
            <Form form={addForm} layout="vertical" onFinish={handleAddFinish}>
               <UserForm form={addForm} initialValues={{}} onFinish={handleAddFinish} />
               <div className="gap-5 flex">
                  <Button
                     onClick={() => {
                        setAddModal(false)
                     }}
                     size="lg"
                     variant="faded"
                     className="w-20"
                     color="danger"
                  >
                     {t('buttons.cancel')}
                  </Button>

                  <Button type="submit" size="lg" variant="shadow" className="w-20" color="primary" isLoading={false}>
                     {t('buttons.save')}
                  </Button>
               </div>
            </Form>
         </Modal>
         <Modal
            open={editModal}
            title={t('buttons.edit')}
            footer={null}
            onCancel={() => {
               setEditModal(false)
               setRow(null)
            }}
            closable
         >
            <Form form={editForm} layout="vertical" onFinish={handleUpdateUser}>
               <UserForm form={editForm} initialValues={row} onFinish={handleUpdateUser} />
               <div className="gap-5 flex">
                  <Button
                     onClick={() => {
                        setEditModal(false)
                     }}
                     size="lg"
                     variant="faded"
                     className="w-20"
                     color="danger"
                  >
                     {t('buttons.cancel')}
                  </Button>

                  <Button type="submit" size="lg" variant="shadow" className="w-20" color="primary" isLoading={false}>
                     {t('buttons.save')}
                  </Button>
               </div>
            </Form>
         </Modal>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0">
               <Button onClick={() => setAddModal(true)} color={'primary'}>
                  {t('buttons.add')}
               </Button>
            </div>
         </PageHeader>

         {/* <ListTable
        data={usersData?.data?.data || []}
        isLoaded={usersLoading}

        // bottomContent={<Pagination
        //     total={usersData?.getUsersPagination?.pagination.pages[usersData?.getUsersPagination?.pagination.pages.length - 1]}
        //     onChange={(page) => setPage(page)}
        //     page={page}

        //     color={"primary"}
        // />}
        // topContent={
        //     <div className="flex justify-end">

        //     </div>
        // }
      /> */}
         <Card title="Filter" className="mb-5">
            <CardHeader>
               <h3 className="text-lg font-semibold">Filter Table</h3>
            </CardHeader>
            <CardBody className="grid grid-cols-4 gap-2 items-center justify-center">
               <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />
               <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5">
                  Filter
               </Button>
            </CardBody>
         </Card>
         <Card title={t('aside.users')}>
            <TableComponents
               columns={columns}
               data={usersData?.data?.data || []}
               loading={usersLoading}
               pagination={{
                  total: usersData?.data?.total || 0,
                  current: page,
                  pageSize: 10,
                  onChange: (page: any) => setPage(page)
               }}
            />
         </Card>
      </>
   )
}
