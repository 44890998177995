import { Input } from 'rizzui'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
   onChangeOne: (e: React.ChangeEvent<HTMLInputElement>) => void
   onChangeTwo: (e: React.ChangeEvent<HTMLInputElement>) => void
   valueOne: string
   valueTwo: string
   hasOne: boolean
   hasTwo: boolean
}

function DateRange(props: Props) {
   const { t } = useTranslation()
   const { onChangeOne, onChangeTwo, valueOne, valueTwo, hasOne, hasTwo } = props
   return (
      <div className="grid grid-cols-2 gap-5">
         {hasOne ? (
            <span>
               <b>{t('common.from')}</b>
               <Input type="date" className="outline-0" value={valueOne} onChange={onChangeOne} placeholder="From" />
            </span>
         ) : null}
         {hasTwo ? (
            <span>
               <b>{t('common.to')}</b>
               <Input type="date" className="outline-0" value={valueTwo} onChange={onChangeTwo} placeholder="To" />
            </span>
         ) : null}
      </div>
   )
}

export default DateRange
